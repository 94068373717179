/* ipadpro */
@media {

}
@media (max-width: 1025px) {
    .search_form__input{
        height: 53px !important;
    }
    .search_main_form__from-back_container, .search_inner-from_from-to_container{
        flex-basis: 100%;
        max-width: 100%;
        margin-bottom: .5% !important;

    }

    .search_form__input.date, .search_form__input.date_inner,.search_form__input.from, search_form__input.to{
        flex-basis: 100%;
        max-width:  49.5%;
        margin-bottom: .5%;
        margin-right: 0;
    }

    .search_form__input.pessangers, .search_form__input.pessangers_inner {
        flex-basis: 100%;
        max-width:  49.5%;
        margin-right: 0;
        box-sizing: border-box;
        margin-bottom: .5%;
    }
    .search_form__switch_button{
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        right: calc(-20px);
        z-index: 1;
        top: 6px;
        height: 40px;
        width: 40px;
        box-shadow: 0 3px 6px rgba(0,0,0,0.16);
        background: #f9f9f9;
        border: 1px solid #eeeeee;
    }
    .search_inner-form__submit_box{
        max-width: 100%;
        flex-basis: 100%;
        margin-top: 2rem;
    }
    .search_inner-form__submit_box .search_form__submit{
        height: 60px;
        box-shadow: 0 3px 6px rgba(0,0,0,0.16);
        border-radius: 4px;
        padding: 0 1.25rem;
        background: #FA8405;
        color: white;
        font-size: 1.5rem;
        display: flex;
        align-items: center;
        margin: 0 auto;
        outline: 0 !important;
        width: auto;
    }
}
/* iphonexr */
@media (max-width: 600px) {
    .search_main_form__from-back_container, .search_inner-from_from-to_container{
        flex-basis: 100%;
        max-width: 100%;
        margin-bottom: 0;
    }
    button.search_form_datePicker_control_not-ticket{
        margin-bottom: 0.5rem;
        margin-top: 0;
    }
    .search_form__input.from, .search_form__input.to{
        margin-right:0 ;
        max-width: 100%;
        flex-basis: 100%;
        margin-bottom: 4px;
    }
    .search_form__input.date{
        flex-basis: 100%;
        max-width: 49.5%;
        margin-right: 0;
        margin-bottom: 4px;
    }
    .search_form__input.pessangers {
        flex-basis: 100%;
        max-width: 49.5%;
        margin-right: 0;
        box-sizing: border-box;
    }
    .search_form__switch_button {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        right: 1rem;
        z-index: 1;
        top: calc(100% - 25px);
        height: 50px;
        width: 50px;
        box-shadow: 0 3px 6px rgba(0,0,0,0.16);
        background: #f9f9f9;
        border: 1px solid #eeeeee;
    }
    .search_form__switch_button svg{
        transform: rotate(90deg);
    }
    .search_form__dialog_content{
        padding: 0.5rem;
        max-height: initial;
        background: var(--primary-color);
        height: 100%;
    }
    .search_form__autoC_list {
        max-height: 87vh;
    }
    .search_form__counter_container{
        max-width: 100%;
    }
    .search_form_link{
        margin: auto;
    }
}