/*@import "bs.css";*/


/* удалитть */
.index-wrapper .promo-wrapper{
  flex-wrap: wrap;
}
.promo-slider{
  display: none !important;
}
/* удалитть */
.search_form__container{
  width: 100%;
  margin: 1rem auto;
}
.search_form__box{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.search_main_form__from-back_container{
  display: flex;
  flex-wrap: wrap;
  flex-basis: 50%;
  max-width: 50%;
  box-sizing: border-box;
  justify-content: space-between;
}
.search_form__input{
  margin-right: 2px;
  background: white;box-shadow: 0 3px 6px rgba(0,0,0,0.16);position: relative;border-radius: 4px;display: flex;flex-direction: column;justify-content: center;padding: 0.5rem 2rem 0.5rem 1.25rem;color: #393939;}
.search_form__input.from,
.search_form__input.to{
  flex-basis: 100%;
  max-width: 25%;
  position: relative;
  box-sizing: border-box;
  /* margin-right: 2px !important; */
}
.search_form__input_label{
  font-size: 0.75rem;

  margin-bottom: 0.25rem;
  color: #707070;
}
.search_form__input_inputText{
  font-size: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 2px;
}
.search_form__input_inputText::placeholder{
  text-overflow: ellipsis;
}
.search_form__input.date{
  flex-basis: 100%;
  max-width: 20%;
  box-sizing: border-box;
}
.search_form__input.pessangers{
  flex-basis: 100%;
  max-width: 15%;
  margin-right: 0;
  box-sizing: border-box;
}

.search_form__submit_box{
  flex-basis: 100%;
  max-width: 100%;
  margin-top: 2rem;
}
.search_form__submit{
  height: 60px;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16);
  border-radius: 4px;
  padding: 0 1.25rem;
  background: #FA8405;
  color: white;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  margin: 0 auto;
  outline: 0 !important;
  border: 0;
}
.search_form__submit:active{
  transition: transform 0.2s;
  transform: scale(0.95);
}

.MuiCircularProgress-colorPrimary {
  color: white !important;
}


.search_form__links{
  flex-basis: 100%;
  max-width: 100%;
  text-align: right;
  margin-top: 0.5rem ;
}

.search_form__links a{
  color: white;
  font-size: .9rem;
}
.search_form__links a:hover{
  color: #FA8405;
  transition: color 0.2s;
}
.search_form__input_icon{
  position: absolute;
  right: 1.25rem;
  bottom: calc(.5rem + 2px);
}
.search_form__input_icon svg{
  color: #393939;
}
.promo-wrapper:after{
  display: none;
}
.search_form__h1{
  color: white;
  font-size: 2.5rem;
  padding: 0;
  margin: 0 0 .5rem;
  text-align: center;
  font-weight: bold;
}
.search_form__h3{
  color: white;
  font-size: 1.5rem;
  padding: 0;

  text-align: center;
  margin-bottom: 3rem ;
  font-weight: bold;
}
.search_form__input.date_from .search_form__input_icon, .search_form__input.date_to .search_form__input_icon {
  transform: scale(0.85);
  bottom: 3px;
}
.autoC__container{
  width: 300px;
  height: auto;
  position: fixed;
  box-shadow: 0 3px 6px rgba(0,0,0,.16);
  background: white;
  border-radius: 4px;
  padding: 1rem;
}
.select-check{
  height: auto;
}

.search_form_icon{
  position: absolute;
  right: 1rem;
  bottom: 0.4rem;

}
.search_form_icon svg{
  width: 18px;
  height: 18px;
  fill: #393939;
}
.search_form__inputModal_inputText{
   background: white;
   box-shadow: 0 3px 6px rgba(0,0,0,0.16);
  padding: 1rem 1rem;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
  font-size: 1rem;
  margin-bottom: .5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
div[data-placement="bottom-start"]{
  z-index: 999999;
}
div[data-placement="top-start"]{
  z-index: 999999;
}
div[data-placement="bottom-end"]{
  z-index: 999999;
}
.search_inner-from_from-to_container{
  display: flex;
  flex-wrap: wrap;
  flex-basis: 50%;
  max-width: 43%;
  box-sizing: border-box;
  justify-content: space-between;
}
.search_form__input.date_inner{

flex-basis: 100%;
  max-width: 20%;
  box-sizing: border-box;

}
.search_inner-form__submit_box{
  flex-basis: 100%;
  max-width: 12%;
}
.search_inner-form__submit_box .search_form__submit{
  height: 100%;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16);
  border-radius: 4px;
  padding: 0 1rem;
  background: #FA8405;
  color: white;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  outline: 0 !important;
  width: 100%;
  text-align: center;
  border: 0;
}
.search_form_link{
  margin-left: auto;
  margin-top: 5px;
  margin-right: 5px;
}
.search_form_link a{
  color: white;
  text-decoration: none;
}
.disable_submit{
  background: #d8d8d8;
}



