
.bf_form{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
  &--icon{

      top: calc(50% - 12px);
      /* left: 50%; */
      /* transform: translateY(-50%); */
      right: 7.5px;
      position: absolute;
      /* width: 24px; */
  svg{
    width: 16px;
    height: 16px;
  }
  }
  &-group {
    margin: .25rem 0;
    position: relative;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #393939;
  }

  &-control{
    &--label{
      font-size: 80%;
      color: white;
      margin-bottom: 3px;
    }
    &--input{
      background: white;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
      position: relative;
      border-radius: 3px;
      line-height: 1;
      height: 36px;
      padding: 0 .5rem;
      display: flex;
      align-items: center;

      &-item{
        font-size: 1rem;
        width: 100%;
        text-overflow: ellipsis;
        line-height: 1;
        white-space: nowrap;
        overflow: hidden;
      border: 0;
      }
    }

    &--placeholder{
      color: #7a7a7a;
      font-family: sans-serif;
      line-height: 1;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

    }
  }
  &--action{
    margin: .25rem 0;
    align-self: flex-start;
    margin-top: auto;
    &-size{
      flex-grow: .3;
      flex-basis: 10%;
    }
    &-btn{
      background: #fa8406;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
      padding: 0.5rem;
      color: white;
      border-radius: 3px;
      font-size: 16px;
      text-align: center;
      width: 100%;
    }
  }
  &--size{
    flex-basis: 22%;
    &:first-child{
      flex-basis: 25%;
      max-width: 25%;
    }
    &:nth-child(2){
      flex-basis: 25%;
      max-width: 25%;
    }
    &:nth-child(3){
      flex-basis: 18%;
      max-width: 18%;
      .bf_form-control--input-item{
        margin-top: 1px;
      }
    }
    &:nth-child(4){
      flex-basis: 13%;
      max-width: 13%;
    }

  }
  &--spiner{
    width: 16px !important;
    height: 16px !important;
  }
  &--autoC-list{

    border-radius: 4px;
    background-color: white;
    box-shadow: 0 3px 6px rgba(0,0,0,.16);
    min-width: 300px;

    padding: 0;
    margin-top: 0.25rem;
    overflow: auto;
    &-item{
      padding: 0.5rem 1rem;
      min-height: 25px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      list-style: none;
      &:active{
        transition: background-color 0.3s;
        background-color: #DEDEDE;
      }
      &.selected{
        background-color: #DEDEDE;
      }
      &:hover{
        background: #eee;
      }
    }
    &-header{
      padding: 0.5rem 1rem;
      background: #eee;
      font-size: 14px;
      position: sticky;
      top: 0;
      left: 0;
      width: inherit;
      border-bottom: 1px solid #d8d8d8;
    }
    }


  @media (max-width: 768px) {
    &--size:nth-child(n){
      flex-basis: 49%;
      max-width: 49%;
    }
    &--action{
     margin: 1rem auto;
    }
  }
  @media (max-width: 425px) {
    &--size:nth-child(n){
      flex-basis: 100%;
      max-width: 100%;
    }
    &--action{
      margin: 1rem auto;
    }
  }
  &-dialog{
    padding: 0.5rem;
    max-height: initial;
    background: var(--primary-color);
    height: 100%;

    &--icon{

      top: calc(50% - 12px);
      /* left: 50%; */
      /* transform: translateY(-50%); */
      right: 7.5px;
      position: absolute;
      /* width: 24px; */

    }
    &-group {
      margin: .25rem 0;
      position: relative;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: #393939;
    }
    &-control{
      &--label{
        font-size: 80%;
        color: white;
        margin-bottom: 3px;
      }
      &--input{
        background: white;
        box-shadow: 0 3px 6px rgba(0,0,0,0.16);
        padding: 1rem 1rem;
        border-radius: 4px;
        width: 100%;
        box-sizing: border-box;
        font-size: 1rem;
        margin-bottom: .5rem;
        display: flex;
        align-items: center;
        justify-content: space-between;


        &-item{
          font-size: 1rem;
          width: 100%;
          text-overflow: ellipsis;
          line-height: 1;
          white-space: nowrap;
          overflow: hidden;
          border: 0;
        }
      }

      &--placeholder{
        color: #7a7a7a;
        font-family: sans-serif;
        line-height: 1;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

      }
    }
    &--action{
      margin: .25rem 0;
      align-self: flex-start;
      margin-top: auto;
      &-size{
        flex-grow: .3;
        flex-basis: 10%;
      }
      &-btn{
        background: #fa8406;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
        padding: 0.5rem;
        color: white;
        border-radius: 3px;
        font-size: 16px;
        text-align: center;
        width: 100%;
      }
    }
    &--size{
      flex-basis: 22%;
      &:first-child{
        flex-basis: 25%;
        max-width: 25%;
      }
      &:nth-child(2){
        flex-basis: 25%;
        max-width: 25%;
      }
      &:nth-child(3){
        flex-basis: 18%;
        max-width: 18%;
        .bf_form-control--input-item{
          margin-top: 1px;
        }
      }
      &:nth-child(4){
        flex-basis: 13%;
        max-width: 13%;
      }

    }
    &--spiner{
      width: 16px !important;
      height: 16px !important;
    }
  }
}

