.search_form__autoC_list{
    background: white;
    border-radius: 4px;
    background-color: white;
    box-shadow: 0 3px 6px rgba(0,0,0,.16);
    min-width: 300px;

    padding: 0;
    margin-top: 0.25rem ;
    overflow: auto;
}
.search_form__autoC_list_item {
    padding: 0.5rem 1rem;
    min-height: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    list-style: none;
}
.search_form__autoC_list_header{
    padding: 0.5rem 1rem;
    background: #eee;
    font-size: 14px;
    position: sticky;
    top: 0;
    left: 0;
    width: inherit;
    border-bottom: 1px solid #d8d8d8;
}
.search_form__autoC_list_item:active{
    transition: background-color 0.3s;
    background-color: #DEDEDE;
}
.search_form__autoC_list_item.selected{
    background-color: #DEDEDE;
}
.search_form__input_inputText{
    background: transparent;
}
.disable{
    background: #eeeeee;
}
.search_form__autoC_list_item:hover{
    background: #eee;
}
.hovered{
    background: #eee !important;
}